import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies-reactive'
import vuetify from './plugins/vuetify'
import { i18n } from './plugins/vue-i18n'
import VueTheMask from "@/plugins/vue-the-mask"
import filters from '@/plugins/filters'
import axios from "@/plugins/axios"
import LottieVuePlayer from "@/plugins/lottie-vue"
import helpers from "@/plugins/helpers"
import vueMoment from "@/plugins/moment"
import VueApexCharts from "@/plugins/apexcharts"

// Import global css
import "@/assets/css/global.css"

// Vue-cookies
Vue.use(VueCookies)

// Global filters
Vue.use(filters)

// Global helpers
Vue.use(helpers)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  VueTheMask,
  axios,
  LottieVuePlayer,
  vueMoment,
  VueApexCharts,
  created() {
    document.documentElement.setAttribute('lang', 'tr')
  },
  render: h => h(App)
}).$mount('#app')

import axios from "axios"
import Vue from "vue"

export default {
    namespaced: true,

    state: {
        provisions: [],
        provisionsLength: 0,
        lastSearchedQuery: null,
        lastAxiosAbortController: null
    },

    getters: {
        getProvisions: state => state.provisions,
        getProvisionById: state => provisionId => state.provisions?.find(provision => provision.id === provisionId),
        getProvisionsLength: state => state.provisionsLength,
        getLastSearchedQuery: state => state.lastSearchedQuery,
        getLastAxiosAbortController: state => state.lastAxiosAbortController
    },

    mutations: {
        setProvisions: (state, provisions) => state.provisions = provisions,
        setProvisionsLength: (state, provisionsLength) => state.provisionsLength = provisionsLength,
        setLastSearchedQuery: (state, query) => state.lastSearchedQuery = query,
        setLastAxiosAbortController: (state, controller) => state.lastAxiosAbortController = controller
    },

    actions: {
        async sendInvoice({getters, commit}, { provision }) {
            try {
                let formData = new FormData()

                // Fill form data with selected provision
                formData.append(`provision_id`, provision.id)
                formData.append(`invoice_number`, provision.invoice.number)
                formData.append(`invoice_date`,
                    Vue.moment(provision.invoice.date, "DD/MM/YYYY").format('YYYY-MM-DDTHH:mm:ss')
                )
                formData.append(`invoice_amount`, provision.invoice.amount)
                formData.append(`invoice_tax`, provision.invoice.tax)

                // Add invoice documents to form data
                const invoiceDocuments = provision.invoice.docs;
                for (let docIndex in invoiceDocuments) {
                    let status = invoiceDocuments[docIndex].getMetadata('status') ?? 'ADD'

                    if (status === 'ALREADY_EXISTS')
                        formData.append(`invoice_docs[${docIndex}][id]`, invoiceDocuments[docIndex].getMetadata('id'))

                    formData.append(`invoice_docs[${docIndex}][file]`, status === 'ADD' ? invoiceDocuments[docIndex].file
                        : invoiceDocuments[docIndex].source)
                    formData.append(`invoice_docs[${docIndex}][status]`, status)
                }

                let response = await axios.post(`policy/policy-provision/invoice/create`, formData)

                if (response.status === 200 && response.hasOwnProperty('data') && response.data?.success) {
                    Vue.notify('Fatura iletildi')
                    return true
                }
            } catch(e) {console.log(e)}

            return false
        },

        async approveInvoices({getters, commit}, { provisionId }) {
            let formData = new FormData()
            formData.append('provision_id', provisionId)

            let response = await axios.post(`policy/policy-provision/invoice/approval`, formData)

            if (response.status === 200 && response.hasOwnProperty('data') && response.data?.success) {
                Vue.notify('Tahakkuk başlatıldı')
                return true
            }
            return false
        },

        async denyInvoice({getters, commit}, { provisionId, denyNote }) {
            let formData = new FormData()
            formData.append('provision_id', provisionId)
            formData.append('note', denyNote)

            let response = await axios.post(`policy/policy-provision/invoice/deny`, formData)

            if (response.status === 200 && response.hasOwnProperty('data') && response.data?.success) {
                Vue.notify('İptal edildi')
                return true
            }
            return false
        },

        /**
         * provisionFilters: Array
         * @param commit
         * @param filters
         */
        filtersObjectToQueryParam({commit}, filters) {
            let query = ""

            filters.provisionStatuses?.forEach(status => {
                query += "&provision_statuses[]=" + status
            })

            if (filters?.dateRange !== null && Array.isArray(filters?.dateRange) && filters?.dateRange.length === 2) {
                query += "&begin_date=" + filters.dateRange[0] + "&end_date=" + filters.dateRange[1]
            }

            if (filters?.provisionNo !== null) {
                query += "&provision_no=" + filters.provisionNo
            }

            if (Object.keys(filters?.provisionStatuses || {}).length === 0)
                query += "&provision_statuses[]=CONFIRMED&provision_statuses[]=PRE_ACCRUAL&provision_statuses[]=DENIED_ACCRUAL"
                    + "&provision_statuses[]=INVOICE_SEND&provision_statuses[]=DENIED_INVOICE"

            return query
        },

        async search({getters, commit, dispatch}, {page = 1, perPage = 10, filters = {}, runLastSearchedQuery = false}) {
            // Cancel last api request
            if (getters['getLastAxiosAbortController'] instanceof AbortController) getters['getLastAxiosAbortController'].abort()

            commit('setLastAxiosAbortController', new AbortController())

            let searchQuery = runLastSearchedQuery ? getters['getLastSearchedQuery'] :
                `?page=${page}&per_page=${perPage}${await dispatch('filtersObjectToQueryParam', filters)}`

            // Set last searched query
            commit('setLastSearchedQuery', searchQuery)

            let response = await axios.get(`policy/policy-provision/search${searchQuery}`, {
                // For cancelable request
                signal: getters['getLastAxiosAbortController'].signal
            })

            if (response.status === 200 && response.hasOwnProperty('data')) {
                let provisionsPageObject = response.data
                let provisionsLength = provisionsPageObject.meta.hasOwnProperty('last_page') ? provisionsPageObject.meta.total : 0

                commit('setProvisions', provisionsPageObject.data.map((provision) => ({
                    ...provision, ...{
                        selected: false,
                        invoice: {
                            number: provision?.invoice?.invoice_number,
                            date: provision?.invoice !== null ? Vue.moment(provision?.invoice?.invoice_date).format('DD/MM/YYYY') : null,
                            amount: provision?.invoice?.invoice_amount,
                            tax: provision?.invoice?.invoice_tax,
                            docs: provision?.invoice?.invoice_documents
                        }
                    }
                })).sort((provision1, provision2) => new Date(provision1.confirmedAt) - new Date(provision2.confirmedAt)))

                // Set provisions length to state
                commit('setProvisionsLength', provisionsLength)
            }
        },

        async provisionInvoiceRegister({}, { provisionNumber, invoiceNumber, invoiceDate, invoiceAmount, invoiceTax }) {
            try {
                let formData = new FormData()
                formData.append('provision_number', Vue.options.filters.provisionNumber(provisionNumber))
                formData.append('invoice_number', invoiceNumber)
                formData.append('invoice_date', invoiceDate)
                formData.append('invoice_amount', invoiceAmount)
                formData.append('invoice_tax', invoiceTax)

                let response = await axios.post('policy/policy-provision/invoice/register', formData)

                if (response.status === 200 && response.hasOwnProperty('data')) {
                    return true
                }
            } catch (e) { console.log(e) }
            return false
        }
    }
}

import axios from 'axios'
import Vue from 'vue'
import router from '@/router'

export default {
    namespaced: true,

    state: {
        authUser: null,
        showLoadingDialog: false
    },

    getters: {
        isAuthenticated: (state, getters) => !!getters.authToken,
        getAuthUser: state => Vue.$cookies.get('authUser') || state.authUser,
        authToken: state => Vue.$cookies.get('token') || state.authUser?.token,
        permissions: (state, getters) => getters.getAuthUser?.policy_permissions ?? [],
        hasPermission: (state, getters) => permission => getters.permissions.includes(permission),
        userType: (state, getters) => {
            if (!getters.hasPermission('confirm provision') && getters.hasPermission('provision invoice send'))
                return 'accountant'
            else
                return 'user'
        },
        getShowLoadingDialog: state => state.showLoadingDialog
    },
    mutations: {
        setAuthUser: (state, authUser) => state.authUser = authUser,
        setShowLoadingDialog: (state, isShow) => state.showLoadingDialog = isShow
    },
    actions: {
        async login ({commit, state, getters}, user) {
            try {
                // Show loading dialog
                commit('setShowLoadingDialog', true)

                let response = await axios.post('policy/login', user)

                if (response.status === 200 && response.hasOwnProperty('data')) {
                    // Set auth info (personal info, token, etc.)
                    commit('setAuthUser', response.data)

                    // Set cookies
                    await Vue.$cookies
                        .set('authUser', {
                            user: {
                                id: state.authUser.user.id,
                                name: state.authUser.user.name,
                                last_name: state.authUser.user.last_name,
                                email: state.authUser.user.email,
                            },
                            policy_permissions: state.authUser.policy_permissions,
                            service_provider_id: state.authUser.service_provider_id,
                            service_providers: state.authUser.service_providers
                        }, '1d')
                        .set('token', state.authUser.token, '1d')
                }
            } catch (ex) {console.log(ex)}

            // Hide loading dialog
            commit('setShowLoadingDialog', false)
        },
        logout({commit}) {
            // Remove auth cookies
            Vue.$cookies.remove('authUser')
            Vue.$cookies.remove('token')

            // Set auth user to null
            commit('setAuthUser', null)

            // Redirect to login
            router.replace('login')
        }
    }
}

import axios from 'axios'
import Vue from 'vue'


export default {
    namespaced: true,

    state: {
        userGroups: null,
        serviceProviders: null,
        permissions: null,
        selectedPermissions: [],
        selectedUser: null,
        editedUser: null,
        users: []
    },

    getters: {
        getUserGroups: state => state.userGroups,
        getServiceProviders: state => state.serviceProviders,
        getPermissions: state => state.permissions,
        getSelectedPermissions: state => state.selectedPermissions,
        getSelectedUser: state => state.selectedUser,
        getUsers: state => state.users,
        getEditedUser: state => state.editedUser

    },
    mutations: {
        setUserGroups: (state, userGroups) => state.userGroups = userGroups,
        setServiceProviders: (state, serviceProviders) => state.serviceProviders = serviceProviders,
        setPermissions: (state, permissions) => state.permissions = permissions,
        pushSelectedPermission: (state, permissionId) => state.selectedPermissions.push(permissionId),
        removeSelectedPermission: (state, permissionId) => state.selectedPermissions.splice(state.selectedPermissions.indexOf(permissionId), 1),
        setSelectedUser: (state, userId) => state.selectedUser = userId,
        setUsers: (state, users) => state.users = users,
        setEditedUser: (state, user) => state.editedUser = user
    },
    actions: {
        async fetchUserGroup({commit}) {
            let response = await axios.get('userGroups/list')

            if (response.status === 200 && response.hasOwnProperty('data')) {
                // Set plans to state
                commit('setUserGroups', response.data.map(group => ({
                    id: group.id,
                    group_name: group.name
                })))
            }
        },
        async fetchServiceProvider({commit}) {
            let response = await axios.get('policy/policy/register/service-provider-list')

            if (response.status === 200 && response.hasOwnProperty('data')) {
                // Set plans to state
                commit('setServiceProviders', response.data.data.map(provider => ({
                    id: provider.id,
                    service_provider_name: provider.name
                })))
            }
        },
        async fetchPermission({commit}) {
            let response = await axios.get('policy/policy/register/list-permissions')

            if (response.status === 200 && response.hasOwnProperty('data')) {
                // Set plans to state
                commit('setPermissions', response.data.data.map(permission => ({
                    id: permission.id,
                    category: permission.category,
                    permissionName: permission.permission_name,
                    description: permission.description
                })))
            }
        },
        async fetchUsers({commit}) {
            let response = await axios.get('policy/policy/register/user-list')

            if (response.status === 200 && response.hasOwnProperty('data')) {
                // Set plans to state
                commit('setUsers', response.data.data.map(user => ({
                    id: user.id,
                    name: user.name,
                    surname: user.surname,
                    email: user.email,
                    phone: user.phone,
                    userHasGroup: user.user_group,
                    serviceProvider: user.service_provider,
                    permissions: user.permissions,
                })))
            }
        },
        addPermissionToSelectedPermission({commit}, permissionId) {
            commit("pushSelectedPermission", permissionId)
        },
        removePermissionFromSelectedPermission({commit}, permissionId) {
            commit("removeSelectedPermission", permissionId)
        },
        async fetchUser({commit}, userId) {
            let response = await axios.get(`policy/policy/register/get-user?id=${userId}`)

            if (response.status === 200 && response.hasOwnProperty('data')) {
                let user = response.data.data

                // Set user to state
                commit('setSelectedUser', user)

                // Set user to state for change user data
                commit('setEditedUser', {
                    id: user.id,
                    email: user.email,
                    name: user.name,
                    surname: user.surname,
                    phone: user.phone,
                    permissions: user.permissions?.map(permission => ({
                        id: permission.id,
                        category: permission.category,
                        permissionName: permission.permission_name,
                        description: permission.description
                    })),
                    service_provider: user.service_provider?.map(serviceProvider => ({
                        id: serviceProvider.id,
                        service_provider_name: serviceProvider.name
                    })),
                    user_group: user.user_group?.map(group => ({
                        id: group.id,
                        group_name: group.name
                    }))
                })
            }
        },
        async resetPassword({commit}, data) {
            try {
                // Fill form data
                let formData = new FormData()
                formData.append('user_id', data.userId)
                formData.append('password', data.password)
                formData.append('_method', 'put')

                let response = await axios.post(`policy/policy/register/reset-password`, formData)

                if (response.status === 200 && response.hasOwnProperty('data') && response.data.success) {
                    Vue.notify('Parola sıfırlandı', 'success', 5000)
                }
            } catch (e) {
            }
        },
        async submit({commit, state}, data) {
            try {

                const userGroupIds = data.user_group.map(group => group.id)
                const serviceProviderIds = data.service_provider.map(provider => provider.id)
                const permissionIds = data.permissions.map(permission => permission.id)

                // Object to form data
                let formData = new FormData()
                formData.set('name', data.name)
                formData.set('surname', data.surname)
                formData.set('email', data.email)
                formData.set('phone', parseInt(data.phone))
                formData.set('password', data.password)
                for (const index in data.user_group) {
                    formData.set(`user_group[${index}]`, userGroupIds[index])
                }
                for (const index in data.service_provider) {
                    formData.set(`service_provider[${index}]`, serviceProviderIds[index])
                }
                for (const index in data.permissions) {
                    formData.set(`permissions[${index}]`, permissionIds[index])
                }

                // Submit
                let response = await axios.post('policy/policy/register/create', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                })

                if (response.status === 201 && response.hasOwnProperty('data') && response.data?.success) {
                    Vue.notify('Kullanıcı oluşturuldu', 'success', 5000)
                    return response.data.data
                }

            } catch (e) {
            }
            return false
        },
        async update({commit, state}, data) {
            try {
                const userGroupIds = data.user_group.map(group => group.id)
                const serviceProviderIds = data.service_provider.map(provider => provider.id)
                const permissionIds = data.permissions.map(permission => permission.id)

                // Object to form data
                let formData = new FormData()
                formData.append('_method', 'put')
                formData.append('id', data.id)
                formData.append('name', data.name)
                formData.append('surname', data.surname)
                formData.append('email', data.email)
                formData.append('phone', parseInt(data.phone))
                for (const index in data.user_group) {
                    formData.append(`user_group[${index}]`, userGroupIds[index])
                }
                for (const index in data.service_provider) {
                    formData.append(`service_provider[${index}]`, serviceProviderIds[index])
                }
                for (const index in data.permissions) {
                    formData.append(`permissions[${index}]`, permissionIds[index])
                }

                // Submit
                let response = await axios.post('policy/policy/register/update', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                })

                if (response.status === 200 && response.hasOwnProperty('data') && response.data?.success) {
                    Vue.notify('Kullanıcı güncellendi', 'success', 5000)
                }

            } catch (e) { console.log(e) }
        }
    }
}

import Vue from 'vue'
import VueI18n from 'vue-i18n'

// Languages
import tr from '/src/locales/tr.json'

Vue.use(VueI18n)

export const i18n = new VueI18n({
    locale: 'tr',
    messages: Object.assign({
        tr
    })
})

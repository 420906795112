<template>
  <div>
    <v-snackbar
        v-for="(notification, index) in notifications"
        :key="index"
        v-model="notification.show"
        :timeout="notification.timeout"
        :style="{top: (index * 60) + 'px', zIndex: 10000000}"
        :color="notifyColor(notification.type)"
        centered
        top
        right
    >
      {{ notification.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="notification.show = false"
        >
          Kapat
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "Notify",
  watch: {
    jsonNotifications(notifications){
      if(!JSON.parse(notifications).some(notification => notification.show))
        this.$store.dispatch("notify/removeOutOfTimeMessages")
    }
  },
  computed: {
    notifications() {
      return this.$store.getters["notify/getNotifications"]
    },
    jsonNotifications() {
      return JSON.stringify(this.$store.getters["notify/getNotifications"])
    }
  },
  methods: {
    notifyColor(type) {
      return {
        success: 'success',
        error: 'error'
      }[type]
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <div class="fab-area-navbar">
      <v-speed-dial
          v-model="fab"
          top
          right
          direction="top"
          transition="slide-x-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn
              v-model="fab"
              color="#f73464"
              dark
              fab
              x-large
          >
            <v-icon v-if="fab">
              mdi-menu-open
            </v-icon>
            <v-icon v-else>
              mdi-menu
            </v-icon>
          </v-btn>
        </template>
        <div class="menu-item-area">
          <router-link
              v-if="$store.getters['auth/hasPermission']('view policy')"
              to="/"
              tag="span"
              replace
          >
            <v-btn
                block
                small
                color="white"
                elevation="4"
            >
              Poliçe Sorgulama
              <v-icon
                  large
                  color="#f73464"
              >
                manage_search
              </v-icon>
            </v-btn>
          </router-link>
          <router-link
              v-if="$store.getters['auth/hasPermission']('write policy')"
              to="/write-policy"
              tag="span"
              replace
          >
            <v-btn
                block
                small
                color="white"
                elevation="4"
            >
              Poliçe Üretme
              <v-icon
                  large
                  color="#f73464"
              >
                receipt
              </v-icon>
            </v-btn>
          </router-link>
          <router-link
              v-if="$store.getters['auth/hasPermission']('provision invoice send')"
              to="/accounting"
              tag="span"
              replace
          >
            <v-btn
                block
                small
                color="white"
                elevation="4"
            >
              Muhasebe İşlemleri
              <v-icon
                  large
                  color="#f73464"
              >
                analytics
              </v-icon>
            </v-btn>
          </router-link>
          <router-link
              v-if="$store.getters['auth/hasPermission']('user manage')"
              to="/users"
              tag="span"
              replace
          >
            <v-btn
                block
                small
                color="white"
                elevation="4"
            >
              Kullanıcı
              <v-icon
                  large
                  color="#f73464"
              >
                account_box
              </v-icon>
            </v-btn>
          </router-link>
        </div>
      </v-speed-dial>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data(){
    return {
      fab: false
    }
  }
}
</script>

<style scoped lang="scss">
  .fab-area-navbar {
    position: fixed;
    bottom: 50px;
    right: 0;
    z-index: 1;
    .v-btn--floating {
      position: relative;
    }
    .menu-item-area {
      .v-btn {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 30px 20px;
        border-radius: 10px;
      }
    }
  }
</style>
<style lang="scss">
  .fab-area-navbar {
    .v-speed-dial__list {
      right: 190px;
      left: auto !important;
      align-items: start !important;
      flex-direction: column !important;
    }
  }
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./modules/auth"
import policyWrite from "./modules/policy-write"
import policyPayment from "./modules/policy-payment"
import policies from "./modules/policies"
import provision from "./modules/provision"
import invoiceApproval from "./modules/invoice-approval"
import notify from "./modules/notify"
import users from "@/store/modules/users"
import statistic from "@/store/modules/statistic"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    policyWrite,
    policyPayment,
    policies,
    provision,
    invoiceApproval,
    notify,
    users,
    statistic
  }
})

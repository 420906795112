import axios from "axios"
import Vue from "vue"
import { i18n } from "@/plugins/vue-i18n";

export default {
    namespaced: true,

    state: {
        policies: null,
        policiesLength: 0,
        paymentPendingPolicies: null,
        policyDocumentTypes: null,
        loadingDialog: false,
        loadingDialogMessage: 'Poliçeler getiriliyor'
    },

    getters: {
        getPolicies: state => state.policies,
        getPoliciesLength: state => state.policiesLength,
        getPaymentPendingPolicies: state => state.paymentPendingPolicies,
        getPolicyDocumentTypes: state => state.policyDocumentTypes,
        getLoadingDialog: state => state.loadingDialog,
        getLoadingDialogMessage: state => state.loadingDialogMessage
    },

    mutations: {
        setPolicies: (state, policies) => state.policies = policies,
        setPoliciesLength: (state, policiesLength) => state.policiesLength = policiesLength,
        setPaymentPendingPolicies: (state, policies) => state.paymentPendingPolicies = policies,
        setPolicyDocumentTypes: (state, types) => state.policyDocumentTypes = types,
        changeLoadingDialog: (state, { isShow, message = 'Poliçeler getiriliyor' }) => {
            state.loadingDialog = isShow
            state.loadingDialogMessage = message
        }
    },

    actions: {
        async loadPolicies ({commit}, { page, searchString = "", loading = true, serviceProviders = [],
            beginDate = null, endDate = null, paymentTypes = [], policyStatus = [],
            orderBy = null, orderType = null}) {
            // Show loading dialog
            if (loading)
                commit('changeLoadingDialog', {
                    isShow: true
                })

            // Get policies by page
            // let response = await axios.get(`policy/policy/list-by-service-provider?page=${page}`)
            let response = await
                axios.get(`policy/policy/search?page=${page}
                ${searchString !== "" ? '&search_string=' + searchString : ''}
                ${beginDate !== null ? '&start_date=' + beginDate : ''}
                ${endDate !== null ? '&end_date=' + endDate : ''}
                ${serviceProviders !== [] ? serviceProviders.map(providerId => '&service_providers[]=' + providerId).join() : ''}
                ${paymentTypes !== [] ? paymentTypes.map(type => '&payment_type[]=' + type).join() : ''}
                ${policyStatus !== [] ? policyStatus.map(status => '&policy_status[]=' + status).join() : ''}
                ${orderBy !== null ? '&order_by=' + orderBy : ''}
                ${orderType !== null ? '&order_type=' + orderType : ''}`)

            if (response.status === 200 && response.hasOwnProperty('data')) {
                let policies = response.data.data
                // let policesLength = response.data.hasOwnProperty('last_page') ? response.data.last_page : 0
                let policesLength = response.data.meta.hasOwnProperty('last_page') ? response.data.meta.last_page : 0

                // Set policies to state
                commit('setPolicies', policies)

                // Set policies length to state
                commit('setPoliciesLength', policesLength)
            }

            // Hide loading dialog
            if (loading)
                commit('changeLoadingDialog', {
                    isShow: false
                })
        },
        async getPolicyPdf({commit}, {policyId, type = 'offer'}){
            try {
                // Show loading dialog
                commit('changeLoadingDialog', {
                    isShow: true,
                    message: `${type === 'offer' ? 'Teklif' : 'Poliçe'} hazırlanıyor`
                })

                // Get policy pdf
                let response = await axios.get(`policy/policy/get-policy-pdf?policy_id=${policyId}&type=${type}`)

                if (response.status === 200 && response.hasOwnProperty('data')) {
                    let responseData = response.data?.data
                    let policyLink = responseData?.link?.link

                    // Open policy pdf in new tab
                    open(policyLink, '_blank')
                }
            } catch (e) {}

            // Hide loading dialog
            commit('changeLoadingDialog', {
                isShow: false
            })
        },
        async getCollectionReceipt({commit}, {policyId}){
            try {
                // Show loading dialog
                commit('changeLoadingDialog', {
                    isShow: true,
                    message: 'Tahsilat makbuzu hazırlanıyor'
                })

                // Get collection receipt
                let response = await axios.get(`policy/policy/get-collection-receipt?policy_id=${policyId}`)

                if (response.status === 200 && response.hasOwnProperty('data')) {
                    let responseData = response.data?.data
                    let policyLink = responseData?.link?.link

                    // Open policy pdf in new tab
                    open(policyLink, '_blank')
                }
            } catch (e) {}

            // Hide loading dialog
            commit('changeLoadingDialog', {
                isShow: false
            })
        },
        async resendPaymentMessage({commit}, policyId) {
            try {
                // Show loading dialog
                commit('changeLoadingDialog', {
                    isShow: true,
                    message: 'Mesaj gönderiliyor'
                })

                // Fill form data
                let formData = new FormData();
                formData.set('policy_id', policyId);

                let response = await axios.post(`policy/policy/send-policy-payment-link`, formData)

                if (response.status === 200 && response.hasOwnProperty('data') && response.data.success) {
                    Vue.notify('Mesaj gönderildi', 'success', 5000)
                }
            } catch (e) {}

            // Hide loading dialog
            commit('changeLoadingDialog', {
                isShow: false
            })
        },
        async sendPolicyCancellationMail({commit}, { policyId, email}) {
            try {
                // Show loading dialog
                commit('changeLoadingDialog', {
                    isShow: true,
                    message: 'Mail gönderiliyor'
                })

                // Fill form data
                let formData = new FormData();
                formData.set('policy_id', policyId);
                formData.set('email', email);

                let response = await axios.post(`policy/policy/send-policy-cancellation-mail-sample`, formData)

                if (response.status === 200 && response.hasOwnProperty('data') && response.data.success) {
                    Vue.notify('Mail gönderildi', 'success', 5000)
                }
            } catch (e) {}

            // Hide loading dialog
            commit('changeLoadingDialog', {
                isShow: false
            })
        },
        async sendPolicyDocument({commit}, { policyId, channel, to, type = 'offer'}) {
            try {
                // Show loading dialog
                commit('changeLoadingDialog', {
                    isShow: true,
                    message: `${type === 'offer' ? 'Teklif' : 'Poliçe'} gönderiliyor`
                })

                // Fill form data
                let formData = new FormData()
                formData.set('policy_id', policyId)
                formData.set('channel', channel)
                formData.set('to', to)
                formData.set('type', type)

                let response = await axios.post(`policy/policy/send-policy-document`, formData)

                if (response.status === 200 && response.hasOwnProperty('data') && response.data.success) {
                    Vue.notify(`${type === 'offer' ? 'Teklif' : 'Poliçe'} gönderildi`, 'success', 5000)
                }
            } catch (e) {}

            // Hide loading dialog
            commit('changeLoadingDialog', {
                isShow: false
            })
        },
        async sendCollectionReceipt({commit}, { policyId, channel, to}) {
            try {
                // Show loading dialog
                commit('changeLoadingDialog', {
                    isShow: true,
                    message: 'Tahsilat makbuzu gönderiliyor'
                })

                // Fill form data
                let formData = new FormData()
                formData.set('policy_id', policyId)
                formData.set('channel', channel)
                formData.set('to', to)

                let response = await axios.post(`policy/policy/send-collection-receipt`, formData)

                if (response.status === 200 && response.hasOwnProperty('data') && response.data.success) {
                    Vue.notify('Tahsilat makbuzu gönderildi', 'success', 5000)
                }
            } catch (e) {}

            // Hide loading dialog
            commit('changeLoadingDialog', {
                isShow: false
            })
        },
        async controlPreAuthPolicy({commit}, policyId) {
            try {
                // Show loading dialog
                commit('changeLoadingDialog', {
                    isShow: true,
                    message: i18n.t('policy.isAuthControl.loading')
                })
                let customErrorMessage = i18n.t('policy.isAuthControl.fail')

                let response = await axios.get(`policy/policy/pre-auth?policy_id=${policyId}`)

                if (response.status === 200 && response.hasOwnProperty('data') && response.data.success) {
                    let isAuth = response.data.hasOwnProperty('data') ?
                        (response.data.data.hasOwnProperty('is_auth') ? response.data.data.is_auth : false) : false

                    let message = isAuth ? i18n.t('policy.isAuthControl.success') :
                        (response.data.hasOwnProperty('data') ?
                            (response.data.data.hasOwnProperty('message') ?
                                response.data.data.message : customErrorMessage) : customErrorMessage)

                    Vue.notify(message, isAuth ? 'success' : 'error', 5000)
                }
                else {
                    Vue.notify(customErrorMessage, 'error', 5000)
                }
            } catch (e) {console.log(e)}

            // Hide loading dialog
            commit('changeLoadingDialog', {
                isShow: false
            })
        },
        async generateManually({commit}, { policyId, generatedAt = null }) {
            try {
                // Fill form data
                let formData = new FormData()
                formData.set('policy_id', policyId)

                // Set "generated at" date when it is set
                if (generatedAt !== null) formData.set('generated_at', generatedAt)

                let response = await axios.post(`policy/policy/generate-manually`, formData)

                if (response.status === 200 && response.hasOwnProperty('data') && response.data.success) {
                    Vue.notify('Poliçe oluşturuldu', 'success', 5000)
                }
            } catch (e) {}
        },
        async getPaymentPendingPolicies ({commit}) {
            let response = await axios.get(`policy/policy/list-payment-pending-policies`)

            if (response.status === 200 && response.hasOwnProperty('data')) {
                let policies = response.data.data

                // Set payment pending policies to state
                commit('setPaymentPendingPolicies', policies)
            }
        },
        async getPolicyDocuments({commit}, { policyId }) {
            try {
                let response = await axios.get(`policy/policy/document/list-by-policy?policy_id=${policyId}`)

                if (response.status === 200 && response.hasOwnProperty('data')) {
                    return response.data.data
                }
            } catch (e) {}
            return null
        },
        async fetchPolicyDocumentTypes({commit}) {
            let response = await axios.get(`policy/policy/document-type/list`)

            if (response.status === 200 && response.hasOwnProperty('data')) {
                let types = response.data.data

                // Set payment pending policies to state
                commit('setPolicyDocumentTypes', types)
            }
        },
        async uploadPolicyDocument({commit}, { policyId, documentTypeId, file }) {
            try {
                let formData = new FormData()
                formData.set('policy_id', policyId)
                formData.set('document_type_id', documentTypeId)
                formData.set('file', file)

                let response = await axios.post(`policy/policy/document/upload`, formData)

                if (response.status === 200 && response.hasOwnProperty('data') && response.data.success) {
                    Vue.notify('Doküman kaydedildi', 'success', 5000)
                    return true
                }
            } catch (e) {}
            return false
        }
    }
}

export default function badgeColor(value) {
    return {
        CREATED: {
            backgroundColor: '#4caf50 !important',
            color: '#fff !important'
        },
        PAID: {
            backgroundColor: '#fb8c00 !important',
            color: '#fff !important'
        },
        COMPLETED: {
            backgroundColor: '#ff5252 !important',
            color: '#fff !important'
        }
    }[value]
}

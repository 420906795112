import axios from 'axios'
import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        policy: null,
        paymentResponse: null,
        successfulPayment: false
    },

    getters: {
        getPolicy: state => state.policy,
        getPaymentResponse: state => state.paymentResponse,
        getSuccessfulPayment: state => state.successfulPayment
    },
    mutations: {
        setPolicy: (state, policy) => state.policy = policy,
        setPaymentResponse: (state, response) => state.paymentResponse = response,
        setSuccessfulPayment: (state, isSuccessful) => state.successfulPayment = isSuccessful
    },
    actions: {
        async fetchPolicy ({commit, state, getters, dispatch}, paymentKey) {
            let response = await axios.get('policy/policy-payment/get-payment-info', {
                params: {
                    payment_key: paymentKey
                }
            })
            if (response.status === 200 && response.hasOwnProperty('data')) {
                // Set policy info to state
                commit('setPolicy', response.data.data)
            }
        },
        async paySubmit({commit, state, getters, dispatch}, data){
            try {
                let formData = new FormData()
                for(let key in data) {
                    formData.set(key, data[key])
                }

                let response = await axios.post('policy/generate', formData)

                if (response.hasOwnProperty('data')) {
                    if (response.status === 200) {
                        // Set payment response to state
                        commit('setPaymentResponse', response.data.data)

                        // Set successfulPayment flag to true
                        commit('setSuccessfulPayment', true)
                    }

                    // Show notification
                    Vue.notify(response.data.message, response.data.success ? 'success' : 'error')
                }
                else {
                    // Show notification
                    Vue.notify('Beklenmedik bir hata oluştu', 'error')
                }
            } catch (e) {}
        },
    }
}

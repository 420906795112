import axios from 'axios'
import Vue from "vue"

const getDefaultState = () => {
    return {
        products: null,
        selectedProduct: null,
        productChooserDialog: false,
        showLoadingDialog: false,
        loadingDialogText: 'İşlem gerçekleştiriliyor',
        showSuccessDialog: false,
        policyResponse: null,
        isPhoneApproved: false,
        isPolicyHolderPhoneApproved: false
    }
}

export default {
    namespaced: true,

    state: getDefaultState(),

    getters: {
        getProducts: (state) => state.products,
        getProductsForProductChooser: (state) => {
            return state.products?.map((product) => {
                return {
                    id: product.id, // Insurance company product
                    productId: product.product_id,
                    image: product.service.image,
                    name: product.service.name,
                    insuredTypes: product.plans.map(plan => plan.insured_type),
                    installmentCounts: product.plans.map(plan => plan.installment_count),
                    paymentTypes: product.plans.map(plan => plan.payment_type),
                    insuranceCompanyId: product.insurance_company_id,
                    insuranceCompanyLogo: product.insurance_company_logo,
                    serviceProvider: product.service_provider,
                    plans: product.plans
                }
            }) || []
        },
        getSelectedProduct: state => (state.selectedProduct !== null ? {
            id: state.selectedProduct.id,
            productId: state.selectedProduct.product_id,
            image: state.selectedProduct.service !== undefined ? state.selectedProduct.service.image : state.selectedProduct.image,
            name: state.selectedProduct?.service !== undefined ? state.selectedProduct.service.name : state.selectedProduct.name,
            insuredTypes: state.selectedProduct.plans.map(plan => plan.insured_type),
            installmentCounts: state.selectedProduct.plans.map(plan => plan.installment_count),
            paymentTypes: state.selectedProduct.plans.map(plan => plan.payment_type),
            insuranceCompanyId: state.selectedProduct.insurance_company_id,
            insuranceCompanyLogo: state.selectedProduct.insurance_company_logo,
            serviceProvider: state.selectedProduct.service_provider,
            plans: state.selectedProduct.plans
        } : null),
        getProductChooserDialog: state => state.productChooserDialog,
        getShowSuccessDialog: state => state.showSuccessDialog,
        getShowLoadingDialog: state => state.showLoadingDialog,
        getLoadingDialogText: state => state.loadingDialogText,
        getPolicyResponse: state => state.policyResponse,
        getIsPhoneApproved: state => state.isPhoneApproved,
        getIsPolicyHolderPhoneApproved: state => state.isPolicyHolderPhoneApproved
    },
    mutations: {
        resetState: state => Object.assign(state, getDefaultState()),
        setProducts: (state, products) => state.products = products,
        setSelectedProduct: (state, product) => state.selectedProduct = product,
        changeShowSuccessDialogState: (state, show) => state.showSuccessDialog = show,
        changeShowLoadingDialogState: (state, show) => {
            if (show instanceof Object) {
                state.showLoadingDialog = show.show
                state.loadingDialogText = show?.text ?? 'İşlem gerçekleştiriliyor'
            }
            else {
                state.showLoadingDialog = show
                state.loadingDialogText = 'İşlem gerçekleştiriliyor'
            }
        },
        setPolicyResponse: (state, response) => state.policyResponse = response,
        setIsPhoneApproved: (state, isPhoneApproved) => state.isPhoneApproved = isPhoneApproved,
        setIsPolicyHolderPhoneApproved: (state, isPhoneApproved) => state.isPolicyHolderPhoneApproved = isPhoneApproved,
    },
    actions: {
        setDefaultStates({commit}) {
            commit('resetState')
        },
        async fetchProducts({commit, state, getters, dispatch}) {
            // Show loading dialog
            commit("changeShowLoadingDialogState", {
                show: true,
                text: 'Ürünler getiriliyor'
            })

            let response = await axios.get('policy/insurance-product/list-by-service-provider')

            if (response.status === 200 && response.hasOwnProperty('data')) {
                // Set plans to state
                commit('setProducts', response.data.data)

                // Set first product as selected
                dispatch('setSelectedProduct', state.products[0] ?? {})
            }

            // Hide loading dialog
            commit("changeShowLoadingDialogState", false)
        },
        setSelectedProduct({commit}, product) {
            commit('setSelectedProduct', product)
        },
        changeStatusProductChooserDialog({state}, status) {
            state.productChooserDialog = status
        },
        async phoneApprovedControl({commit}, {phone, type = 'insured'}) {
            let response = await axios.get(`policy/policy/kvkk/is-approved?phone=${phone}`)

            if (response.status === 200 && response.hasOwnProperty('data') && response.data?.success
                    && response.data.hasOwnProperty('data') && response.data.data.hasOwnProperty('isApproved')) {
                let isApproved = response.data.data.isApproved

                // Change is approved status
                commit(type === 'insured' ? 'setIsPhoneApproved' : 'setIsPolicyHolderPhoneApproved', isApproved)

                // Show notification if is approved
                if (isApproved)
                    Vue.notify('Telefon numarası daha önceden onaylanmış', 'success', 3000)

                return isApproved
            }

            return false
        },
        async sendPhoneApprovalMessage({commit}, phone) {
            // Create form data with phone
            let formData = new FormData()
            formData.set('phone', phone)

            let response = await axios.post('policy/policy/kvkk/send-approval-message', formData)

            if ((response.status === 200 || response.status === 201) && response.hasOwnProperty('data')
                && response.data?.success) {
                // Show notification
                Vue.notify('Onay kodu gönderildi', 'success')
            }
        },
        /**
         * @param commit
         * @param phone
         * @param code
         * @param type
         * @returns {Promise<boolean>}
         */
        async sendPhoneApprovalCode({commit}, {phone, code, type}) {
            try {
                // Create form data with phone and code attributes
                let formData = new FormData();
                formData.set('phone', phone)
                formData.set('code', code)

                let response = await axios.post('policy/policy/kvkk/approve-code', formData)

                if (response.status === 200 && response.hasOwnProperty('data') && response.data?.success) {
                    // Set phone approved flag to true
                    commit(type === 'insured' ? 'setIsPhoneApproved' : 'setIsPolicyHolderPhoneApproved', true)

                    return true
                }
            } catch (e) {}
            return false
        },
        async submit({commit, state}, data) {
            try {
                // Object to form data
                let formData = new FormData()
                for (const key in data) {
                    formData.set(key, data[key])
                }

                // Submit
                let response = await axios.post('policy/policy/create', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                    timeout: 3 * 60 * 1000 // 3 minutes
                })

                // Set response to state
                commit("setPolicyResponse", response?.data)

                // Hide loading dialog
                commit("changeShowLoadingDialogState", false)

                if (response.status === 201 && response.hasOwnProperty('data') && response.data?.success) {
                    // Show success dialog
                    commit("changeShowSuccessDialogState", true)

                    return
                }

                // if (response.hasOwnProperty('data'))
                //     Vue.notify(response.data.message, 'error')
                // else
                //     Vue.notify("Bir hata oluştu")
            } catch (e) {
                // Hide loading dialog
                commit("changeShowLoadingDialogState", false)

                // Show notification
                // Vue.notify(JSON.parse(e?.request?.response ?? "")?.message || "Bir hata oluştu", 'error')
            }
        },
        showSuccessDialog: ({commit}) => commit("changeShowSuccessDialogState", true),
        hideSuccessDialog: ({commit}) => commit("changeShowSuccessDialogState", false),
        showLoadingDialog: ({commit}) => commit("changeShowLoadingDialogState", true),
        hideLoadingDialog: ({commit}) => commit("changeShowLoadingDialogState", false)
    }
}

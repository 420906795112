export default function provisionStatus(value) {
    let statuses = {
        NOT_CONFIRMED: 'Onaylanmadı',
        CONFIRMED: 'Onaylandı',
        INVOICE_SEND: 'Fatura Gönderildi',
        DENIED_INVOICE: 'Fatura Reddedildi',
        PRE_ACCRUAL: 'Ön Tahakkuk',
        ACCRUAL: 'Tahakkuk',
        DENIED_ACCRUAL: 'Tahakkuk İptal'
    }

    if (value instanceof Array) return value.map( status => statuses[status] )

    return statuses[value]
}

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('v-app', [_vm.$store.getters['auth/isAuthenticated'] ? _c('ProfileMenu') : _vm._e(), _vm.$store.getters['auth/isAuthenticated'] ? _c('Navbar') : _vm._e(), _c('Notify'), _c('router-view')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
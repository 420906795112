var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.notifications, function (notification, index) {
    return _c('v-snackbar', {
      key: index,
      style: {
        top: index * 60 + 'px',
        zIndex: 10000000
      },
      attrs: {
        "timeout": notification.timeout,
        "color": _vm.notifyColor(notification.type),
        "centered": "",
        "top": "",
        "right": ""
      },
      scopedSlots: _vm._u([{
        key: "action",
        fn: function (_ref) {
          var attrs = _ref.attrs;
          return [_c('v-btn', _vm._b({
            attrs: {
              "text": ""
            },
            on: {
              "click": function ($event) {
                notification.show = false;
              }
            }
          }, 'v-btn', attrs, false), [_vm._v(" Kapat ")])];
        }
      }], null, true),
      model: {
        value: notification.show,
        callback: function ($$v) {
          _vm.$set(notification, "show", $$v);
        },
        expression: "notification.show"
      }
    }, [_vm._v(" " + _vm._s(notification.message) + " ")]);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store"

// Added for "Avoided redundant navigation to current location" exception
// e.g. same url error
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/auth/LoginView'),
        meta: {
            title: 'Giriş',
            notAuthenticated: true
        }
    },
    {
        path: '/',
        name: 'policies',
        component: () => import('../views/policy/PoliciesView'),
        meta: {
            title: 'Poliçeler',
            permissions: [
                'view policy'
            ]
        }
    },
    {
        path: '/write-policy',
        name: 'write-policy',
        component: () => import('../views/policy/PolicyWriteView'),
        meta: {
            title: 'Poliçe Üretme',
            permissions: [
                'write policy'
            ]
        }
    },
    {
        path: '/payment',
        name: 'payment',
        component: () => import('../views/policy/PolicyPaymentView'),
        meta: {
            title: 'Ödeme',
            notAuthenticated: true
        }
    },
    {
        path: '/accounting',
        name: 'accounting',
        component: () => import('../views/accounting/AccountingLandingView'),
        children: [
            {
                path: 'provision-invoice',
                name: 'provision-invoice',
                component: () => import('../views/accounting/InvoiceApprovalView'),
                meta: {
                    title: 'Fatura Onaylama',
                    permissions: [
                        'provision invoice send'
                    ]
                }
            },
            {
                path: 'statistics',
                name: 'statistics',
                component: () => import('../views/accounting/StatisticsView'),
                meta: {
                    title: 'İstatistikler',
                    permissions: [
                        'statistic show'
                    ]
                }
            }
        ],
        meta: {
            title: 'Muhasebe'
        }
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('../views/user/UserLandingView'),
        children: [
            {
                path: 'list',
                name: 'list',
                component: () => import('../views/user/UserView'),
                meta: {
                    title: 'Kullanıcılar',
                    permissions: [
                        'user manage'
                    ]
                }
            },
            {
                path: 'create',
                name: 'create',
                component: () => import('../views/user/UserCreateView'),
                meta: {
                    title: 'Yeni Kullanıcı Oluştur',
                    permissions: [
                        'user manage'
                    ]
                }
            },
            {
                path: 'edit/:id',
                name: 'edit',
                component: () => import('../views/user/UserEditView'),
                meta: {
                    title: 'Kullanıcıyı Düzenle',
                    permissions: [
                        'user manage'
                    ]
                }
            }
        ],
        meta: {
            title: 'Kullanıcı İşlemleri'
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return {y: 0}
    },
})

/**
 * Auth control
 */
router.beforeEach(async (to, from, next) => {
    // Set page title
    document.title = to.meta.title + ' | White'

    if (!to.meta.notAuthenticated) {
        // Auth control
        if (store.getters["auth/isAuthenticated"]) {
            let isAccountant = store.getters['auth/userType'] === 'accountant'
            // Permission control
            if (to.meta.hasOwnProperty('permissions') && to.meta.permissions.length !== 0) {
                let authUserPermissions = store.getters["auth/permissions"]
                let hasPermission = true
                for (let routePermission in to.meta.permissions) {
                    if (!authUserPermissions.includes(to.meta.permissions[routePermission])) {
                        hasPermission = false
                        break
                    }
                }

                if (hasPermission) {
                    next()
                    return
                }
                else {
                    // If user is 'accountant', his main page is must be /accounting
                    if (isAccountant) {
                        if (to.path !== '/') Vue.notify('Yetkiniz yok', 'error')
                        next('/accounting')
                    }
                    else {
                        Vue.notify('Yetkiniz yok', 'error')
                        next('/')
                    }
                    return
                }
            }
            else {
                next()
                return
            }
        }
        next('/login')
    } else {
        next()
    }
})

/**
 * Login page control
 * Giriş yapmış kullanıcı tekrar giriş sayfasına girememeli
 */
router.beforeEach(async (to, from, next) => {
    if (to.name === 'login' && store.getters["auth/isAuthenticated"]) {
        store.getters['auth/userType'] === 'accountant' ? next('/accounting') : next('/')
        return
    }
    next()
})

export default router

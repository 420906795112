<template>
  <div id="app">
    <v-app>
      <ProfileMenu v-if="$store.getters['auth/isAuthenticated']"/>
      <Navbar v-if="$store.getters['auth/isAuthenticated']"/>

      <!-- For Notification -->
      <Notify />

      <router-view/>
    </v-app>
  </div>
</template>

<script>
import ProfileMenu from "@/components/nav/ProfileMenu"
import Navbar from "@/components/nav/Navbar"
import Notify from "@/components/global/Notify"

export default {
  components: {
    ProfileMenu,
    Navbar,
    Notify
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

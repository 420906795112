<template>
  <div>
    <v-dialog
        v-model="dialog"
        hide-overlay
        persistent
        width="300"
        :style="dialogStyle"
    >
      <v-card
          color="#36286b"
          dark
      >
        <v-card-text class="pt-2">
          {{ text }}
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-overlay
        :value="dialog"
        :z-index="zIndex !== 'initial' ? zIndex - 1 : 5"
    />
  </div>
</template>

<script>
export default {
  name: "LoadingDialog",
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      required: false,
      default: 'İşlem gerçekleştiriliyor'
    },
    zIndex: {
      type: String,
      required: false,
      default: 'initial'
    }
  },
  computed: {
    dialogStyle() {
      return {
        ...(this.zIndex !== 'initial' ? {zIndex: this.zIndex} : {})
      }
    }
  }
}
</script>

<style scoped>

</style>

import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import tr from "vuetify/lib/locale/tr"

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'md'
    },
    lang: {
        locales: { tr },
        current: "tr",
    },
})

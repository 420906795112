<template>
  <div>
    <div class="fab-area-profile-menu">
      <v-speed-dial
          v-model="fab"
          direction="bottom"
          transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn
              v-model="fab"
              color="#f73464"
              dark
              fab
              large
          >
            <v-icon v-if="fab" x-large>
              face
            </v-icon>
            <v-icon v-else x-large>
              mdi-face-woman-profile
            </v-icon>
          </v-btn>
        </template>
        <div class="menu-item-area">
          <v-btn
              block
              small
              color="white"
              elevation="4"
              @click="logout"
          >
            Çıkış Yap
            <v-icon
                color="#f73464"
            >
              logout
            </v-icon>
          </v-btn>
          <v-btn
              block
              small
              color="white"
              elevation="4"
              @click="showDialog"
          >
            Şifre Sıfırla
            <v-icon
                color="#f73464"
            >
              mdi-lock-reset
            </v-icon>
          </v-btn>
        </div>
      </v-speed-dial>
    </div>
    <ResetPassword v-model="dialog" :selected-user="authUser?.user"/>
  </div>
</template>

<script>
import ResetPassword from "@/components/user/ResetPassword"
export default {
  name: "Navbar",
  components: {
    ResetPassword
  },
  data(){
    return {
      dialog: false,
      fab: false
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
    },
    showDialog() {
      this.dialog = !this.dialog
    }
  },
  computed: {
    authUser() {
      return this.$store.getters["auth/getAuthUser"]
    }
  }
}
</script>

<style scoped lang="scss">
  .fab-area-profile-menu {
    position: fixed;
    top: 1em;
    left: 3em;
    z-index: 1;
    .v-btn--floating {
      position: relative;
    }
    .menu-item-area {
      .v-btn {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px 20px;
        border-radius: 10px;
      }
    }
  }
</style>
<style lang="scss">
  .fab-area-profile-menu {
    .v-speed-dial__list {
      left: auto !important;
      align-items: start !important;
      flex-direction: column !important;
    }
  }
</style>

<template>
  <v-dialog
      v-model="dialogLocal"
      persistent
      max-width="500px">
    <v-card>
      <v-card-title>
        <span class="text-h6">Şifre Sıfırla</span>
      </v-card-title>
      <v-card-text>
        <p><strong>{{ selectedUser?.email }}</strong> şifresini sıfırlamak istediğinize emin misiniz?</p>
        <form autocomplete="off">
          <v-text-field
              v-model="password"
              label="Parola"
              solo-inverted
              clearable
              color="blue-grey lighten-2"
              hint="Parola"
              autocomplete="new-password"
              class="mb-4"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              required
          ></v-text-field>
          <v-btn
              large
              class="px-8 py-6"
              color="#f73464"
              dark
              @click="randomPassword">Rastgele Parola Oluştur
          </v-btn>
        </form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn text @click="closeDialog">İptal</v-btn>
        <v-btn color="error" text @click="resetPassword">Kaydet</v-btn>
      </v-card-actions>
    </v-card>
    <LoadingDialog :dialog="loadingDialog"/>
  </v-dialog>
</template>
<script>
import LoadingDialog from "@/components/global/LoadingDialog";

export default {
  name: "ResetPassword",
  components: {
    LoadingDialog
  },
  model: {
    prop: 'dialog',
    event: 'changeShowDialog'
  },
  props: {
    dialog: {
      type: Boolean,
      required: false,
      default: false
    },
    selectedUser: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      showPassword: false,
      password: '',
      loadingDialog: false,
    }
  },
  computed: {
    dialogLocal: {
      set(newValue) {
        this.$emit('changeShowDialog', newValue)
      },
      get() {
        return this.dialog
      }
    }
  },
  methods: {
    randomPassword() {
      this.password = Math.random().toString(36).slice(-10)
    },
    closeDialog() {
      this.dialogLocal = !this.dialogLocal
      this.password = null
    },
    async resetPassword() {
      let data = {
        userId: this.selectedUser.id,
        password: this.password
      }
      this.loadingDialog = true

      const response = await this.$store.dispatch("users/resetPassword", data)

      this.dialog = this.dialogLocal

      this.loadingDialog = false
    }
  }
}
</script>
<style scoped></style>
import axios from "axios"

export default {
    namespaced: true,
    state: {
        policyCountByDate: null,
        serviceProviderPolicyComparison: null,
        totalStatistics: null,
        serviceProviderBasedPolicyProduction: null,
        lastProvisionApprovals: null,
        lastProducedPolicies: null,
    },
    getters: {
        getPolicyCountByDate: state => state.policyCountByDate,
        getServiceProviderPolicyComparison: state => state.serviceProviderPolicyComparison,
        getTotalStatistics: state => state.totalStatistics,
        getServiceProviderBasedPolicyProduction: state => state.serviceProviderBasedPolicyProduction,
        getLastProducedPolicies: state => state.lastProducedPolicies,
        getLastProvisionApprovals: state => state.lastProvisionApprovals,
    },
    mutations: {
        setPolicyCountByDate: (state, policyCountByDate) => state.policyCountByDate = policyCountByDate,
        setServiceProviderPolicyComparison: (state, serviceProviderPolicyComparison) => state.serviceProviderPolicyComparison = serviceProviderPolicyComparison,
        setTotalStatistics: (state, totalStatistics) => state.totalStatistics = totalStatistics,
        setServiceProviderBasedPolicyProduction: (state, policyProduction) => state.serviceProviderBasedPolicyProduction = policyProduction,
        setLastProducedPolicies: (state, producedPolicies) => state.lastProducedPolicies = producedPolicies,
        setLastProvisionApprovals: (state, provisionApprovals) => state.lastProvisionApprovals = provisionApprovals,
    },
    actions: {
        async fetchServiceProviderPolicyComparison({ commit, dispatch }, { beginDate, endDate, serviceProviders }) {
            let serviceProviderQueryParam = await dispatch('getServiceProviderQueryParam', serviceProviders)

            let response = await axios
                .get(`/policy/policy-statistics/service-provider-comparison?
                begin_date=${beginDate}&
                end_date=${endDate}&
                list_count=3
                ${serviceProviderQueryParam}`)

            if (response.status === 200 && response.hasOwnProperty('data')) {
                commit('setServiceProviderPolicyComparison', response.data.data)
            }
        },
        async fetchTotalStatistics({ commit, dispatch }, { beginDate, endDate, serviceProviders }) {
            let serviceProviderQueryParam = await dispatch('getServiceProviderQueryParam', serviceProviders)

            let response = await axios
                .get(`/policy/policy-statistics/total-statistics?
                begin_date=${beginDate}&
                end_date=${endDate}
                ${serviceProviderQueryParam}`)

            if (response.status === 200 && response.hasOwnProperty('data')) {
                commit('setTotalStatistics', response.data.data)
            }
        },
        async fetchPolicyCountByDate({ commit, dispatch }, { beginDate, endDate, serviceProviders }) {
            let serviceProviderQueryParam = await dispatch('getServiceProviderQueryParam', serviceProviders)

            let response = await axios
                .get(`/policy/policy-statistics/count-of-policy-production?
                begin_date=${beginDate}&
                end_date=${endDate}
                ${serviceProviderQueryParam}`)

            if (response.status === 200 && response.hasOwnProperty('data')) {
                commit('setPolicyCountByDate', response.data.data)
            }
        },
        async fetchServiceProviderBasedPolicyProduction({ commit, dispatch }, { beginDate, endDate, serviceProviders }) {
            let serviceProviderQueryParam = await dispatch('getServiceProviderQueryParam', serviceProviders)

            let response = await axios
                .get(`/policy/policy-statistics/count-of-service-provider-based-policy-production?
                begin_date=${beginDate}&
                end_date=${endDate}
                ${serviceProviderQueryParam}`)

            if (response.status === 200 && response.hasOwnProperty('data')) {
                commit('setServiceProviderBasedPolicyProduction', response.data.data)
            }
        },
        async fetchLastProvisionApprovals({ commit, dispatch }, { beginDate, endDate, serviceProviders }) {
            let serviceProviderQueryParam = await dispatch('getServiceProviderQueryParam', serviceProviders)

            let response = await axios
                .get(`/policy/policy-statistics/last-provision-approvals?
                begin_date=${beginDate}&
                end_date=${endDate}&
                list_count=5
                ${serviceProviderQueryParam}`)

            if (response.status === 200 && response.hasOwnProperty('data')) {
                commit('setLastProvisionApprovals', response.data.data)
            }
        },
        async fetchLastProducedPolicies({ commit, dispatch }, { beginDate, endDate, serviceProviders }) {
            let serviceProviderQueryParam = await dispatch('getServiceProviderQueryParam', serviceProviders)

            let response = await axios
                .get(`/policy/policy-statistics/last-produced-policies?
                begin_date=${beginDate}&
                end_date=${endDate}&
                list_count=5
                ${serviceProviderQueryParam}`)

            if (response.status === 200 && response.hasOwnProperty('data')) {
                commit('setLastProducedPolicies', response.data.data)
            }
        },
        getServiceProviderQueryParam({}, serviceProviders) {
            let serviceProviderQueryParam = ''

            if (serviceProviders !== null && Array.isArray(serviceProviders) && serviceProviders.length !== 0) {
                // Query Parameter separator
                serviceProviderQueryParam = '&'

                // Set service provider ids to parameter string. e.g. 14,53,23,1
                serviceProviders.forEach(serviceProviderId => serviceProviderQueryParam += 'service_providers[]=' + serviceProviderId)
            }

            return serviceProviderQueryParam
        }
    }
}

var render = function render(){
  var _vm$authUser;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "fab-area-profile-menu"
  }, [_c('v-speed-dial', {
    attrs: {
      "direction": "bottom",
      "transition": "slide-y-reverse-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "color": "#f73464",
            "dark": "",
            "fab": "",
            "large": ""
          },
          model: {
            value: _vm.fab,
            callback: function ($$v) {
              _vm.fab = $$v;
            },
            expression: "fab"
          }
        }, [_vm.fab ? _c('v-icon', {
          attrs: {
            "x-large": ""
          }
        }, [_vm._v(" face ")]) : _c('v-icon', {
          attrs: {
            "x-large": ""
          }
        }, [_vm._v(" mdi-face-woman-profile ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.fab,
      callback: function ($$v) {
        _vm.fab = $$v;
      },
      expression: "fab"
    }
  }, [_c('div', {
    staticClass: "menu-item-area"
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "small": "",
      "color": "white",
      "elevation": "4"
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v(" Çıkış Yap "), _c('v-icon', {
    attrs: {
      "color": "#f73464"
    }
  }, [_vm._v(" logout ")])], 1), _c('v-btn', {
    attrs: {
      "block": "",
      "small": "",
      "color": "white",
      "elevation": "4"
    },
    on: {
      "click": _vm.showDialog
    }
  }, [_vm._v(" Şifre Sıfırla "), _c('v-icon', {
    attrs: {
      "color": "#f73464"
    }
  }, [_vm._v(" mdi-lock-reset ")])], 1)], 1)])], 1), _c('ResetPassword', {
    attrs: {
      "selected-user": (_vm$authUser = _vm.authUser) === null || _vm$authUser === void 0 ? void 0 : _vm$authUser.user
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    style: _vm.dialogStyle,
    attrs: {
      "hide-overlay": "",
      "persistent": "",
      "width": "300"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "#36286b",
      "dark": ""
    }
  }, [_c('v-card-text', {
    staticClass: "pt-2"
  }, [_vm._v(" " + _vm._s(_vm.text) + " "), _c('v-progress-linear', {
    staticClass: "mb-0 mt-2",
    attrs: {
      "indeterminate": "",
      "color": "white"
    }
  })], 1)], 1)], 1), _c('v-overlay', {
    attrs: {
      "value": _vm.dialog,
      "z-index": _vm.zIndex !== 'initial' ? _vm.zIndex - 1 : 5
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
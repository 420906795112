import axios from 'axios'
import Vue from "vue"

export default {
    namespaced: true,

    state: {
        // Policy and its provisions info
        policy: null,
        selectedProvision: null
    },

    getters: {
        getPolicy: (state) => state.policy,
        getSelectedProvision: (state) => state.selectedProvision
    },

    mutations: {
        setPolicy: (state, policy) => state.policy = policy,
        setSelectedProvision: (state, provision) => state.selectedProvision = provision
    },

    actions: {
        async fetchPolicy({commit}, policyId) {
            let response = await axios.get(`policy/policy/get?policy_id=${policyId}`)

            if (response.status === 200 && response.hasOwnProperty('data')) {
                let policy = response.data.data

                // Sort provisions by sequence
                policy.provisions = policy.provisions
                    .sort((a,b) => Number.parseInt(a.sequence) - Number.parseInt(b.sequence))

                // Set policy to state
                commit('setPolicy', policy)
            }
        },
        async sendProvisionConfirmationCode({commit}, { provisionId, sendMessage = true }) {
            let formData = new FormData()
            formData.set('policy_provision_id', provisionId)

            if (!sendMessage)
                formData.set('send_message', sendMessage ? '1' : '0')

            let response = await axios.post(`policy/policy-provision/send-confirmation-code`, formData)

            if (response.status === 200 && response.hasOwnProperty('data') && response.data?.success) {
                // Show notification
                Vue.notify('Onay kodu gönderildi', 'success')
            }
        },
        async confirmProvisionConfirmationCode({commit}, {code, provisionId, customDateTime = null}) {
            try {
                let formData = new FormData()
                formData.set('code', code)
                formData.set('policy_provision_id', provisionId)
                if (customDateTime !== null)
                    formData.set('custom_confirmation_date', customDateTime)

                let response = await axios.post(`policy/policy-provision/confirm-confirmation-code`, formData)

                if (response.status === 200 && response.hasOwnProperty('data') && response.data?.success) {
                    // Show notification
                    Vue.notify('Provizyon onaylandı', 'success')

                    return true
                }
            } catch (e) {}
            return false
        },
        async getProvisionFormStream({commit}, provisionId) {
            try {
                let response = await axios.get(`policy/policy-provision/document/provision-form-stream?provision_id=${provisionId}`)

                if (response.status === 200 && response.hasOwnProperty('data') && response.data?.success) {
                    // Open provision form in new tab

                    // window.open(
                    //     "data:application/pdf;base64,"+response.data.data.content, '_blank'
                    // )

                    // For moron! Chrome issue
                    let pdfWindow = window.open("")
                    pdfWindow.document.write(
                        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                        response.data.data.content + "'></iframe>"
                    )
                }
            } catch (e) {}
        },
        async cancelProvision({commit, dispatch, getters}, { provisionId, description, dialog }) {
            try {
                // Validation
                if (description.length < 10) {
                    Vue.notify('İptal notu en az 10 karakter olmalıdır', 'error')
                    return
                }

                let formData = new FormData()
                formData.set('policy_provision_id', provisionId)
                formData.set('description', description)

                let response = await axios.post('policy/policy-provision/cancel-provision', formData)

                if (response.status === 200 && response.hasOwnProperty('data') && response.data?.success) {
                    Vue.notify('Provizyon iptal edildi', 'success')

                    // Refresh policy data
                    dispatch('fetchPolicy', getters['getPolicy']?.id)
                }

                // Close dialog
                dialog.value = false
            } catch (e) {}
        }
    }
}

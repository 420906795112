var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "fab-area-navbar"
  }, [_c('v-speed-dial', {
    attrs: {
      "top": "",
      "right": "",
      "direction": "top",
      "transition": "slide-x-reverse-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "color": "#f73464",
            "dark": "",
            "fab": "",
            "x-large": ""
          },
          model: {
            value: _vm.fab,
            callback: function ($$v) {
              _vm.fab = $$v;
            },
            expression: "fab"
          }
        }, [_vm.fab ? _c('v-icon', [_vm._v(" mdi-menu-open ")]) : _c('v-icon', [_vm._v(" mdi-menu ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.fab,
      callback: function ($$v) {
        _vm.fab = $$v;
      },
      expression: "fab"
    }
  }, [_c('div', {
    staticClass: "menu-item-area"
  }, [_vm.$store.getters['auth/hasPermission']('view policy') ? _c('router-link', {
    attrs: {
      "to": "/",
      "tag": "span",
      "replace": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "small": "",
      "color": "white",
      "elevation": "4"
    }
  }, [_vm._v(" Poliçe Sorgulama "), _c('v-icon', {
    attrs: {
      "large": "",
      "color": "#f73464"
    }
  }, [_vm._v(" manage_search ")])], 1)], 1) : _vm._e(), _vm.$store.getters['auth/hasPermission']('write policy') ? _c('router-link', {
    attrs: {
      "to": "/write-policy",
      "tag": "span",
      "replace": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "small": "",
      "color": "white",
      "elevation": "4"
    }
  }, [_vm._v(" Poliçe Üretme "), _c('v-icon', {
    attrs: {
      "large": "",
      "color": "#f73464"
    }
  }, [_vm._v(" receipt ")])], 1)], 1) : _vm._e(), _vm.$store.getters['auth/hasPermission']('provision invoice send') ? _c('router-link', {
    attrs: {
      "to": "/accounting",
      "tag": "span",
      "replace": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "small": "",
      "color": "white",
      "elevation": "4"
    }
  }, [_vm._v(" Muhasebe İşlemleri "), _c('v-icon', {
    attrs: {
      "large": "",
      "color": "#f73464"
    }
  }, [_vm._v(" analytics ")])], 1)], 1) : _vm._e(), _vm.$store.getters['auth/hasPermission']('user manage') ? _c('router-link', {
    attrs: {
      "to": "/users",
      "tag": "span",
      "replace": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "small": "",
      "color": "white",
      "elevation": "4"
    }
  }, [_vm._v(" Kullanıcı "), _c('v-icon', {
    attrs: {
      "large": "",
      "color": "#f73464"
    }
  }, [_vm._v(" account_box ")])], 1)], 1) : _vm._e()], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
    namespaced: true,

    state: {
        notifications: []
    },

    getters: {
        getNotifications: (state) => state.notifications
    },

    mutations: {
        addNewNotification: (state, notification) => state.notifications.push(notification),
        setNotifications: (state, notifications) => state.notifications = notifications
    },

    actions: {
        show({commit}, {message, type = 'success', timeout = 5000}){
            // Add message for display
            commit("addNewNotification", {
                show: true,
                message,
                type,
                timeout
            })
        },

        /**
         * Removed out of time messages from notifications state
         * @param commit
         * @param getters
         */
        removeOutOfTimeMessages({commit, getters}){
            commit("setNotifications", getters.getNotifications.filter(notification => notification.show))
        }
    }
}

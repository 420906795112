import store from '@/store'

const notify = {
    install(Vue, options) {
        const notify = function (message, type = 'success', timeout = 5000) {
            store.dispatch("notify/show", {
                message,
                type,
                timeout
            })
        }

        Vue.prototype.notify = notify
        Vue.notify = notify
    },
}

export default notify

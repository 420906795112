import installment from './plan/installment'
import insuredType from './plan/insuredType'
import paymentType from './plan/paymentType'
import unmaskPhone from "./global/unmaskPhone"
import status from "./policy/status"
import badgeStatus from "./policy/badgeStatus"
import badgeColor from "./policy/badgeColor"
import maskPhone from "./global/maskPhone"
import provisionStatus from "./provision/status"
import price from "./global/price"
import humanDate from "./global/humanDate"
import insuranceCompany from "./global/insuranceCompany"
import provisionNumber from "./provision/provisionNumber"


export default {
    install(Vue) {
        Vue.filter('installment', installment)
        Vue.filter('insuredType', insuredType)
        Vue.filter('paymentType', paymentType)
        Vue.filter('unmaskPhone', unmaskPhone)
        Vue.filter('maskPhone', maskPhone)
        Vue.filter('policyStatus', status)
        Vue.filter('policyBadgeStatus', badgeStatus)
        Vue.filter('policyBadgeColor', badgeColor)
        Vue.filter('provisionStatus', provisionStatus)
        Vue.filter('price', price)
        Vue.filter('humanDate', humanDate)
        Vue.filter('insuranceCompany', insuranceCompany)
        Vue.filter('provisionNumber', provisionNumber)
    }
}

import axios from "axios"
import store from "@/store"
import Vue from "vue"

/**
 * Axios
 */
axios.defaults.withCredentials = false
axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL
axios.defaults.headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
}

axios.interceptors.request.use(function (config) {
    if(store.getters["auth/authToken"] !== undefined) {
        config.headers = {
            'Authorization': `Bearer ${store.getters["auth/authToken"]}`,
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }

    return config
});

axios.interceptors.response.use(function (response) {
    return response
}, function (errorResponse) {
    try {
        let response = errorResponse.response
        if (response.data.hasOwnProperty('errors')){
            for (let error of Object.values(response.data.errors)) {
                if (Array.isArray(error))
                    error.forEach(err => {
                        Vue.notify(err, 'error', 5000)
                    })

            }
        }
        if (response.data.hasOwnProperty('message') && response.data.hasOwnProperty('success')) {
            Vue.notify(response.data.message, response.data.success ? 'success' : 'error')
        }
    } catch (e) {}

    return Promise.reject(errorResponse);
})

export default axios
